import gif from "../../assets/gif/loading.gif";
import classes from "./LoginForm.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useData } from "../../context/DataContext";
// import logo from "../../assets/logo.png";
const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { login, logout } = useUser();
  const { logo } = useData();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    var emailE = false,
      passwordE = false;
    console.log("i am called");
    console.log(email);
    if (email.length === 0) {
      console.log("here");
      emailE = true;
      setEmailErr("Email cannot be empty. ");
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      emailE = true;
      setEmailErr("Invalid email.");
    } else {
      emailE = false;
      setEmailErr("");
    }
    if (password.length === 0) {
      passwordE = true;
      setPasswordErr("Password cannot be empty ");
    } else if (password.length < 8) {
      passwordE = true;
      setPasswordErr("Password should be atleast 8 characters long.");
    } else {
      passwordE = false;
      setPasswordErr("");
    }

    if (!emailE && !passwordE) {
      setIsLoading(true);
      login(email, password)
        .then((e) => {
          const docRef = doc(db, "admin", e.user.uid);
          getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
              navigate("/users");
            } else {
              // doc.data() will be undefined in this case

              setAuthErr("Invalid Account");
            }
          });
        })
        .catch((e) => {
          setAuthErr(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      {!logo ? (
        <div className="d-flex justify-content-center align-items-center">
          <img src={gif} style={{ width: "200px", height: "200px" }} />
        </div>
      ) : (
        <div>
          <div className={"text-center mb-4 " + classes.pClr}>
            <img
              src={logo}
              width={100}
              height={100}
              className="mb-3"
              style={{ borderRadius: 15 }}
            />
            <h1>ADMIN PANEL</h1>
          </div>

          {authErr.length === 0 ? null : (
            <div className="text-center text-danger">
              <small>{authErr}</small>
            </div>
          )}
          <div className={"form-floating mb-2"}>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={
                emailErr.length === 0
                  ? "form-control"
                  : "form-control is-invalid"
              }
              id="floatingInput"
              placeholder="name@example.com"
            />
            <div className="invalid-feedback">{emailErr}</div>
            <label className="floating-label">Email</label>
          </div>
          <div className={"form-floating mb-2"}>
            <input
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className={
                passwordErr.length === 0
                  ? "form-control"
                  : "form-control is-invalid"
              }
              id="floatingInput"
              placeholder="name@example.com"
            />
            <div className="invalid-feedback">{passwordErr}</div>
            <label className="floating-label">Password</label>
          </div>
          <div className="checkbox mb-2">
            <label>
              <input
                type="checkbox"
                defaultValue="remember-me"
                className={classes.chkBox}
              />{" "}
              Remember me
            </label>
          </div>
          {isLoading ? (
            <div className="text-center">
              <img src={gif} style={{ width: "80px", height: "80px" }} />
            </div>
          ) : (
            <button
              className={"w-100 btn btn-lg text-light " + classes.customBtn}
              type="submit"
            >
              Sign in
            </button>
          )}
          <p className="text-center mt-5 mb-3 text-muted">
            © 2024  All rights reserved by Targetube LLC </p>
        </div>
      )}
    </form>
  );
};

export default LoginForm;
