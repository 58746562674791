import AddCategoryModal from "../utils/AddCategoryModal";
import CategoryModal from "../utils/CategoryModal";
import CategoriesTable from "../components/categoriesTable/CategoriesTable";
import classes from "./Categories.module.css";
import React, { useEffect, useState } from "react";
import { MdOutlineCategory } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { useData } from "../context/DataContext";
import { useScreenSize } from "../context/ScreenSizeDetector";
// import { Pagination } from "react-bootstrap";

const Categories = () => {
  const {
    deleteLoading,
    categoriesData,
    setCategoriesData,
    addCategory,
    deleteCategoryById,
    fetchAndUpdateCategories,
    resetCategoriesForOrder,
  } = useData();
  const isMobile = useScreenSize();
  const [needUpdate, setNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCategories, setfilteredCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [lastCategoryOrder, setLastCategoryOrder] = useState(0);
  const [searchSubcategory, setsearchSubcategory] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage] = useState(12); // Change this to adjust the number of logs per page

  // Calculate total number of pages
  // const totalPages = Math.ceil(filteredCategories.length / postsPerPage);

  // Generate an array of page numbers
  // const pageNumbers = Array.from(
  //   { length: totalPages },
  //   (_, index) => index + 1
  // );

  // Handle page change
  // const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Update current posts based on the current page
  // var currentCategories = filteredCategories.slice(
  //   (currentPage - 1) * postsPerPage,
  //   currentPage * postsPerPage
  // );

  // var lastCategoryOrder = 0;

  //   Filter categpries based on search
  useEffect(() => {
    const filterCategories = () => {
      const filtered = categoriesData.filter((category) => {
        const { categoryName, subcategories } = category;
        const lowerCaseCategory = categoryName
          ? categoryName.toLowerCase()
          : "";
        const lowerCaseSubcategories = subcategories.map((subcategory)=> {

          if (subcategory && subcategory.name) {
            return subcategory.name.toString().toLowerCase();
          } else {
            // Handle the case where subcategory or name is missing or falsy
            return ''; // or any default value you prefer
          }
        }
        );

        // const lowerCaseSubcategories = subcategories.map((subcategory) => {
        //   if (subcategory && subcategory.name) {
        //     return {
        //       ...subcategory,
        //       name: subcategory.name.toString().toLowerCase(),
        //     };
        //   } else {
        //     // Handle the case where subcategory or name is missing or falsy
        //     return subcategory; // or any default value you prefer
        //   }
        // });

        var temparr = lowerCaseSubcategories.map((subcategory) => {
          if (subcategory.includes(searchSubcategory)) return true;
        });

        var temp = temparr.includes(true);

        // Check if category matches search criteria
        return (
          (searchSubcategory === "" || temp) &&
          (searchCategory === "" ||
            lowerCaseCategory.includes(searchCategory.toLowerCase()))
        );
      });

      // Sort the filteredCategories in ascending order using categoryOrder
      const sortedCategories = [...filtered].sort(
        (a, b) => a.categoryOrder - b.categoryOrder
      );

      //  calculate and store new cateory's order
      calculateNewCategoryOrder(sortedCategories);

      // Set the filteredCategories state
      setfilteredCategories(sortedCategories);

      // Slice the current page categories based on postsPerPage
      // currentCategories = sortedCategories.slice(
      //   (currentPage - 1) * postsPerPage,
      //   currentPage * postsPerPage
      // );
    };

    filterCategories();
  }, [categoriesData, searchSubcategory, searchCategory]);

  const calculateNewCategoryOrder = (sortedCategories) => {
    if (sortedCategories.length === 0) {
      // If sortedCategories is empty, set lastCategoryOrder to 1
      setLastCategoryOrder(1);
    } else {
      let missingOrder = 1;
      let foundMissing = false;
      for (let i = 0; i < sortedCategories.length; i++) {
        const currentCategoryOrder = sortedCategories[i].categoryOrder;
        if (currentCategoryOrder !== missingOrder) {
          // If a missing categoryOrder is found, set lastCategoryOrder to that number
          foundMissing = true;
          setLastCategoryOrder(missingOrder - 1);
          break;
        }
        missingOrder++;
      }

      if (!foundMissing) {
        // If all categoryOrders are found, set lastCategoryOrder to the last categoryOrder found
        setLastCategoryOrder(
          sortedCategories[sortedCategories.length - 1]?.categoryOrder
        );
      }
    }
  };

  // Clear search inputs
  const handleClear = () => {
    setSearchCategory("");
    setsearchSubcategory("");
  };

  // Show modal to add new category
  const handleAdd = () => {
    setShowAddCategory(true);
  };

  //  Update categories order in database
  const handleOrderUpdate = async () => {
    setUpdateButtonLoading(true);
    await resetCategoriesForOrder(categoriesData);
    setNeedUpdate(false);
    setUpdateButtonLoading(false);
  };

  return (
    <div className={classes.main}>
      <div className={"p-3 container "}>
        {showAddCategory && (
          <AddCategoryModal
            show={showAddCategory}
            setShow={setShowAddCategory}
            handleAddNewCategory={addCategory}
            lastCategoryOrder={lastCategoryOrder}
            setLastCategoryOrder={setLastCategoryOrder}
          />
        )}

        {show && (
          <CategoryModal
            show={show}
            setShow={setShow}
            category={selectedCategory}
            setCategory={setSelectedCategory}
            handleCategoryUpdate={fetchAndUpdateCategories}
          />
        )}

        <h4 className={classes.mainText + " mb-3"}>
          <strong>Categories</strong>
        </h4>
        <h5>Search</h5>
        <nav className="d-flex flex-wrap justify-content-between align-items-center mb-3">
          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <BiCategory size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Category"
              value={searchCategory}
              onChange={() => setSearchCategory(event.target.value)}
            />
          </div>

          <div className={`input-group mb-3 ${classes.inp}`}>
            <span className="input-group-text">
              <MdOutlineCategory size={16} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search Subcategory"
              value={searchSubcategory}
              onChange={() => setsearchSubcategory(event.target.value)}
            />
          </div>

          <div
            className={
              isMobile
                ? "mb-3 d-flex flex-row-reverse w-100"
                : "mb-3 d-flex flex-row-reverse"
            }
          >
            {needUpdate ? (
              <button
                className="btn btn-outline-success me-2 ms-2"
                disabled={updateButtonLoading}
                onClick={handleOrderUpdate}
              >
                Update Categories
              </button>
            ) : (
              <button
                className="btn btn-outline-success me-2 ms-2"
                onClick={handleAdd}
              >
                <AiOutlinePlus /> Add
              </button>
            )}

            <button
              className={"btn " + classes.btnCustom}
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </nav>

        {loading && <div>Loading...</div>}
        {!loading && (
          <div className="d-flex justify-content-evenly align-items-center">
            <div className={"container"}>
              <div className="">
                <CategoriesTable
                  data={filteredCategories}
                  setCategoriesData={setCategoriesData}
                  setSelectedCategory={setSelectedCategory}
                  setShow={setShow}
                  handleCategoryDelete={deleteCategoryById}
                  deleteLoading={deleteLoading}
                  setNeedUpdate={setNeedUpdate}
                />
              </div>
            </div>
          </div>
        )}

        {/* {!loading && (
          <div className="d-flex justify-content-center">
            <Pagination>
              {pageNumbers.map((pageNumber) => (
                <Pagination.Item
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Categories;
