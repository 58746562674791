import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useData } from "../../context/DataContext";
import classes from "./CategoriesTable.module.css";

const CategoriesTable = ({
  data,
  setCategoriesData,
  setSelectedCategory,
  setShow,
  handleCategoryDelete,
  deleteLoading,
  setNeedUpdate,
}) => {
  // console.log("data: data: data:");
  // console.log(data);
  const { setDeleteLoading } = useData();
  const [move, setMove] = useState(false);
  const [subCategoryMove, setSubCategoryMove] = useState(false);
  const ItemType = "CATEGORY_ROW";

  const moveCategory = (dragIndex, hoverIndex) => {
    setDeleteLoading(true);
    const draggedCategory = data[dragIndex];
    const newData = [...data];

    // Swap the categoryOrder of dragged and hover elements
    const tempOrder = draggedCategory.categoryOrder;
    draggedCategory.categoryOrder = newData[hoverIndex].categoryOrder;
    newData[hoverIndex].categoryOrder = tempOrder;

    // Update your state or data with the new order
    console.log(newData, "new one");
    setCategoriesData(newData);
    setNeedUpdate(true);
  };

  const moveSubCategory = (dragIndex, hoverIndex, categoryIndex) => {
    setDeleteLoading(true);
    const newData = [...data];

    // Swapping the two values
    const temp = newData[categoryIndex].subcategories[dragIndex];
    newData[categoryIndex].subcategories[dragIndex] = data[categoryIndex].subcategories[hoverIndex];
    newData[categoryIndex].subcategories[hoverIndex] = temp;

    // Update your state or data with the new order
    console.log(newData, "new one");
    setCategoriesData(newData);
    setNeedUpdate(true);
  };

  const SubCategoryComponent = ({ categoryIndex, subIndex, subCategory }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag, preview] = useDrag({
      type: "subCategory",
      item: { subIndex },
      collect: (monitor) => ({
        isDragging: subCategoryMove && monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    const [isHeld, setIsHeld] = useState(false);
    const handleDoubleClick = () => {
      if (subCategoryMove) setIsHeld(true);
    };

    const handleTouchStart = () => {
      if (subCategoryMove) setIsHeld(true);
    };

    const handleTouchEnd = () => {
      setIsHeld(false);
    };
    const [, drop] = useDrop({
      accept: "subCategory",
      hover: (item) => {
        if (subCategoryMove) {
          if (!ref.current) {
            return;
          }
          const dragIndex = item.subIndex;
          const hoverIndex = subIndex;
          console.log("drag: " + dragIndex + " to: " + hoverIndex);
          if (dragIndex === hoverIndex) {
            return;
          }
          moveSubCategory(dragIndex, hoverIndex, categoryIndex);
          item.index = hoverIndex;
          setSubCategoryMove(false);
        }
      },
    });
    return (
      <li
        ref={(node) => {
          ref.current = node;
          drag(drop(node));
          // For touch events, you need to use `preview` to attach the touch handlers
          preview(drop(node));
        }}
        className={categoryIndex % 2 === 0 ? `list-group-item ${classes.listItem}` : `list-group-item `}
        style={{
          opacity,
          cursor: isHeld ? "grabbing" : "pointer", // Change cursor style when held
        }}
        key={subIndex}
      >
        <button
          className={classes.dragButton}
          onMouseDown={() => {
            setSubCategoryMove(true);
            handleDoubleClick();
          }}
          onTouchStart={() => {
            setSubCategoryMove(true);
            handleTouchStart();
          }}
          onTouchEnd={() => {
            setSubCategoryMove(false);
            handleTouchEnd();
          }}
        >
          ☰
        </button>
        &nbsp;&nbsp;
        {subCategory.name}
      </li>
    );
  };

  const CategoryRow = ({ category, index }) => {
    const ref = useRef(null);
    const [isHeld, setIsHeld] = useState(false);

    const [{ isDragging }, drag, preview] = useDrag({
      type: ItemType,
      item: { index },
      collect: (monitor) => ({
        isDragging: move && monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemType,
      hover: (item) => {
        if (move) {
          if (!ref.current) {
            return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          if (dragIndex === hoverIndex) {
            return;
          }

          moveCategory(dragIndex, hoverIndex);
          item.index = hoverIndex;
          setMove(false);
        }
      },
    });

    const opacity = isDragging ? 0 : 1;

    const handleDoubleClick = () => {
      if (move) {
        setIsHeld(true);
      }
    };

    const handleTouchStart = () => {
      if (move) {
        setIsHeld(true);
      }
    };

    const handleTouchEnd = () => {
      setIsHeld(false);
    };

    return (
      <tr
        ref={(node) => {
          ref.current = node;
          drag(drop(node));
          // For touch events, you need to use `preview` to attach the touch handlers
          preview(drop(node));
        }}
        style={{
          opacity,
          cursor: isHeld ? "grabbing" : "pointer", // Change cursor style when held
        }}
        // onDoubleClick={handleDoubleClick}
        // onTouchStart={handleTouchStart}
        // onTouchEnd={handleTouchEnd}
      >
        <td>
          {/* {category.categoryOrder} */}
          <button
            className={classes.dragButton}
            onMouseDown={() => {
              setMove(true);
              handleDoubleClick();
            }}
            onTouchStart={() => {
              setMove(true);
              handleTouchStart();
            }}
            onTouchEnd={() => {
              setMove(false);
              handleTouchEnd();
            }}
            // style={{ cursor: isHeld ? "grabbing" : "grab" }} // Change cursor style based on isHeld
          >
            ☰
          </button>
        </td>
        <td>{category.categoryName}</td>
        <td>
        <ul className={"list-group " + classes.listGroup}>
          {category.subcategories.map((subCategory, subIndex) => (
            subCategory && subCategory.name ? (
              <SubCategoryComponent
                key={subIndex}
                subCategory={subCategory??''}
                subIndex={subIndex}
                categoryIndex={index}
              />
            ) : (
              <li key={subIndex} className="list-group-item">
                Subcategory data is missing
              </li>
            )
          ))}
        </ul>
        </td>
        <td>
          <button
            className={"btn btn-outline-primary me-2 mb-2"}
            disabled={deleteLoading}
            onClick={() => {
              setSelectedCategory(category);
              setShow(true);
            }}
          >
            Edit
          </button>

          <button
            className="btn btn-outline-danger mb-2"
            disabled={deleteLoading}
            onClick={() => {
              handleCategoryDelete(category.id);
            }}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="container ">
      <div className="table-responsive ">
        <table className={`table table-striped  ${classes.table}`}>
          <thead>
            <tr className={"table-dark " + classes.thead}>
              <th scope="col"></th>
              <th scope="col">Category</th>
              <th scope="col">Subcategories</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((category, index) => (
              <CategoryRow key={index} category={category} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoriesTable;
