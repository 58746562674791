import Modal from "react-bootstrap/Modal";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import { BiCategory } from "react-icons/bi";
import { v4 as uuidv4 } from 'uuid';

const CategoryModal = ({ show, setShow, category, setCategory, handleCategoryUpdate }) => {
  const [tempData, setTempData] = useState(category);
 const [selectedSubcategories, setSelectedSubcategories] = useState(
    category.subcategories.map((subcategory) => ({
      id: subcategory.id,
      name: subcategory.name,
    }))
  );  //  subcategory options for the react-select component
  const [subcategoryOptions, setSubcategoryOptions] = useState(
    category.subcategories.map((subcategory) => ({
      value: subcategory.id,
      label: subcategory.name,
    }))
  );
  const handleSubcategoryChange = (index, newName) => {
    const updatedSubcategories = [...selectedSubcategories];
    updatedSubcategories[index].name = newName;
    setSelectedSubcategories(updatedSubcategories);
    setTempData({ ...tempData, subcategories: updatedSubcategories });
  };
  //  handles categories inputs
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setTempData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //  handles addition of new subcategories and updating the previous one
  const handleSubcategoriesChange = (selectedOptions) => {
    setSubcategoryOptions(selectedOptions);
   const selectedSubcategories = selectedOptions.map((option) => ({
  id: option.value || uuidv4(),
  name: option.label,
}));

// Check if there are any subcategories that need to be added to the collection
const missingSubcategories = selectedSubcategories.filter(
  (subcategory) => !category.subcategories.some((catSub) => catSub.id === subcategory.id)
);

    // Add the missing subcategories to the existing list of subcategories
    const updatedSubcategories = [...category.subcategories, ...missingSubcategories];

    setCategory((prevData) => ({
      ...prevData,
      subcategories: updatedSubcategories,
    }));

    setTempData((prevData) => ({
      ...prevData,
      subcategories: selectedSubcategories,
    }));
  };

  const handleSubmit = () => {
    handleCategoryUpdate(tempData);
    setShow(false);
  };

  return (
    tempData && (
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter justify-content-center"
            className="d-flex align-items-center "
          >
            <BiCategory className="me-2" />
            Update Category Details
          </Modal.Title>
        </Modal.Header> <Modal.Body>
      <div className="form-group mb-3">
        <h6 htmlFor="categoryName" className="">
          Category Name
        </h6>
        <input
          className="form-control w-50"
          id="categoryName"
          name="categoryName"
          value={tempData.categoryName}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group mb-2">
        <h6 htmlFor="categoryName" className="">
          Subcategories
        </h6>
        <CreatableSelect
          isMulti
          options={subcategoryOptions}
          value={selectedSubcategories.map((sub) => ({
            value: sub.id,
            label: sub.name,
          }))}
getNewOptionData={(inputValue, optionLabel) => ({
  value: uuidv4(),
  label: optionLabel,
})}     
    onChange={(selectedOptions) => {
            
            const selectedSubcategories = selectedOptions.map((option) => ({
              id: option.value ,
              name: option.label,
            }));
            console.log("selllll", selectedSubcategories);

            setSelectedSubcategories(selectedSubcategories);
            setTempData({ ...tempData, subcategories: selectedSubcategories });
          }}
        />
      </div>

      {selectedSubcategories.map((subcategory, index) => (
        <div key={subcategory.id} className="form-group mb-3">
          <h6 htmlFor={`subcategoryName-${subcategory.id}`} className="">
            Edit Subcategory Name
          </h6>
          <input
            className="form-control w-50"
            id={`subcategoryName-${subcategory.id}`}
            name={`subcategoryName-${subcategory.id}`}
            value={subcategory.name}
            onChange={(e) => handleSubcategoryChange(index, e.target.value)}
          />
        </div>
      ))}
    </Modal.Body>
        {/* <Modal.Body>
      <div className="form-group mb-3">
        <h6 htmlFor="categoryName" className="">
          Category Name
        </h6>
        <input
          className="form-control w-50"
          id="categoryName"
          name="categoryName"
          value={tempData.categoryName}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-group mb-2">
        <h6 htmlFor="categoryName" className="">
          Subcategories
        </h6>
        <CreatableSelect
          isMulti
          options={subcategoryOptions}
          value={selectedSubcategories.map((sub) => ({
            value: sub.id,
            label: sub.name,
          }))}
          onChange={(selectedOptions) => {
            const selectedSubcategories = selectedOptions.map((option) => ({
              id: option.value,
              name: option.label,
            }));
            setSelectedSubcategories(selectedSubcategories);
            setTempData({ ...tempData, subcategories: selectedSubcategories });
          }}
        />
      </div>

      {selectedSubcategories.map((subcategory, index) => (
        <div key={subcategory.id} className="form-group mb-3">
          <h6 htmlFor={`subcategoryName-${subcategory.id}`} className="">
            Edit Subcategory Name
          </h6>
          <input
            className="form-control w-50"
            id={`subcategoryName-${subcategory.id}`}
            name={`subcategoryName-${subcategory.id}`}
            value={subcategory.name}
            onChange={(e) => handleSubcategoryChange(index, e.target.value)}
          />
        </div>
      ))}
    </Modal.Body> */}
        {/* <Modal.Body>
          <div className="form-group mb-3">
            <h6 htmlFor="categoryName" className="">
              Category Name
            </h6>
            <input
              className="form-control w-50"
              id="categoryName"
              name="categoryName"
              value={tempData.categoryName}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group mb-2">
            <h6 htmlFor="categoryName" className="">
              Subcategories
            </h6>
            <CreatableSelect
              isMulti
              options={subcategoryOptions}
              value={subcategoryOptions}
              onChange={handleSubcategoriesChange}
            />
          </div>
        </Modal.Body> */}
        <Modal.Footer>
          <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default CategoryModal;
