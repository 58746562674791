// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABb24xxT9vqXimJwao2drzSAA5B60_THE",
  authDomain: "targetupe-new.firebaseapp.com",
  projectId: "targetupe-new",
  storageBucket: "targetupe-new.appspot.com",
  messagingSenderId: "741798869834",
  appId: "1:741798869834:web:19cfb83cedbdd5f7ed9fb3",
  measurementId: "G-914BDMVKNE"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const currentUser = auth.currentUser;
